import { useState } from "react";

import { Icon } from "@/components/Icon";
import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";
import { BUTTONS, LINKS } from "./constants";

import { montserrat } from "@/utils/fonts";

import { AnimatedWrapper, AnimationsEnum } from "@/components/AnimatedWrapper";
import { PolitCampaignBanner } from "@/components/Banners/PolitCampaignBanner";
import MobileNav from "@/components/MobileNav";
import { usePathname } from "next/navigation";

interface Props {
  page?: boolean;
}

const Header = ({ page }: Props) => {
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState<boolean>(false);
  const [openMobileSubMenu, setOpenMobileSubMenu] = useState<string>("");

  const pathname = usePathname();

  return (
    <>
      {/* <PolitCampaignBanner isHidden={isOpenMobileMenu} /> */}

      <div className="w-full relative">
        <AnimatedWrapper
          animationType={
            isOpenMobileMenu
              ? AnimationsEnum.FadeOutUp
              : AnimationsEnum.FadeInDown
          }
          className={clsx(
            montserrat.variable,
            `absolute z-10 top-0 left-0 right-0 pt-10 py-7 w-full px-4 lg:px-6 font-semibold font-montserrat`,
            page ? "bg-white text-[#12074D]" : "text-white"
          )}
        >
          <div className="justify-between flex items-center max-w-7xl mx-auto gap-x-4">
            {/* Logo */}
            <Link href="/" className="flex-shrink-0">
              <Image
                src={
                  page
                    ? "/images/mintology-logo-inverted.webp"
                    : "/images/mintology-logo-new.webp"
                }
                width={164}
                height={23}
                alt="Mintology Logo"
                className="lg:w-[197px] lg:h-[31px] object-contain"
              />
            </Link>
            {/* Mobile view minified menu icon */}
            <button
              className={clsx("flex lg:hidden")}
              onClick={() => setIsOpenMobileMenu(true)}
            >
              <Image
                src={
                  page
                    ? "/images/hamburger-inverted.webp"
                    : "/images/hamburger.webp"
                }
                alt="Mobile Menu Trigger"
                width={33}
                height={22}
              />
            </button>
            {/* Desktop view menu */}
            <div className={clsx("hidden lg:flex")}>
              <ul className="md:flex items-center gap-10 font-medium">
                {LINKS.map((link, i) =>
                  //is Dropdown & had children
                  link.children ? (
                    <div
                      key={link.title + i + "Desktop"}
                      className="relative inline-block group"
                    >
                      <span className="cursor-pointer"> {link.title}</span>
                      <div className="absolute hidden hover:block group-hover:block rounded-b z-50 px-8 py-4 -translate-x-7 shadow-xl pt-3 bg-white text-[#12074D]">
                        <div className="flex flex-col">
                          {link.children.map((sublink, i) => (
                            <li
                              key={sublink.title + i + "Desktop"}
                              className="py-2"
                            >
                              <Link
                                href={sublink.url}
                                target={sublink.newTab ? "_blank" : undefined}
                                className="whitespace-nowrap "
                              >
                                <span className="flex gap-1">
                                  {sublink.icon ? (
                                    <Icon name={sublink.icon as any} />
                                  ) : null}
                                  {sublink.title}
                                </span>
                              </Link>
                            </li>
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : (
                    //is a clickable href
                    <li key={link.title + i + "Desktop"} className="relative">
                      <Link
                        href={link.url}
                        target={link.newTab ? "_blank" : undefined}
                        className="pt-2"
                      >
                        {link.title}
                      </Link>
                      {pathname === link.url && (
                        <span
                          className={clsx(
                            "absolute -bottom-2 left-1/2 -translate-x-1/2 hidden lg:flex w-5 h-[2px] rounded-md overflow-hidden",
                            page ? "bg-[#12074D]" : "bg-white"
                          )}
                        />
                      )}
                    </li>
                  )
                )}
              </ul>
            </div>
            <div className="hidden lg:flex lg:gap-4">
              {BUTTONS.map((link, i) => (
                <a
                  key={link.title + i + "desktop"}
                  aria-label={link.title}
                  href={link.url}
                  target="_blank"
                  className={clsx(
                    "rounded-[5px] flex w-[116px] h-[43px] items-center justify-center overflow-hidden relative",
                    !page && "bg-gradient-to-r from-[#4337cc] to-primary-900",
                    page && link.primary && "text-white bg-primary-900",
                    page &&
                      !link.primary &&
                      "text-primary-900 bg-white border-2 border-primary-900"
                  )}
                >
                  {!page && (
                    <span
                      className={clsx(
                        "absolute left-[2px] top-[2px] flex items-center justify-center w-[calc(100%-4px)] h-[calc(100%-4px)] rounded-[5px] bg-[#412782]"
                      )}
                    ></span>
                  )}
                  <span className="relative z-10">{link.title}</span>
                </a>
              ))}
            </div>
          </div>
        </AnimatedWrapper>
        {/* Mobile Menu */}
        <MobileNav
          isOpenMobileMenu={isOpenMobileMenu}
          setIsOpenMobileMenu={setIsOpenMobileMenu}
          openMobileSubMenu={openMobileSubMenu}
          setOpenMobileSubMenu={setOpenMobileSubMenu}
        />
      </div>
    </>
  );
};

export default Header;
